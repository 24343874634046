import React, { Component } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import CurrencyInput from 'shared/components/CurrencyInput';
import FormErrors from 'shared/components/FormErrors';
import Icon from 'shared/components/Icon';
import SplitsTable from '../components/SplitsTable';
import WarningMessage from "../../../../../engines/portal/src/components/common/WarningMessage";

export default class Screen extends Component {

  handleAmountChange = (amount) => {
    this.props.setAmount(amount);
  }

  handleNotesChange = (event) => {
    this.props.setNotes(event.target.value);
  }

  handleCheckImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    const imageType = e.target.name;

    reader.onload = (upload) => {
      const result = upload.target.result;
      this.resizeImage(result).then((resizedImageData) => {
        this.props.uploadImage(
          this.props.mobile_payment,
          imageType,
          resizedImageData
        );
      });
    };

    if (file) reader.readAsDataURL(file);
  }

  resizeImage = (imageDataUrl) => {
    const tempImage = new Image();
    const MAX_WIDTH = 1200;
    const MAX_HEIGHT = 1200;

    return new Promise((resolve, reject) => {
      tempImage.src = imageDataUrl;
      tempImage.onload = function() {
        let width = tempImage.width;
        let height = tempImage.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(this, 0, 0, width, height);
        const dataURL = canvas.toDataURL("image/jpeg");
        const imageBase64 = dataURL.split(',')[1];

        resolve(imageBase64);
      };
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      updatePayment,
      mobile_payment,
      confirmPayment,
      notes,
      amount
    } = this.props;

    updatePayment(mobile_payment, {notes, check_amount: amount})
      .then(() => { confirmPayment(mobile_payment); });
  }

  handleConfirmSubmit = (event) => {
    event.preventDefault();
    const {
      updatePayment,
      mobile_payment,
      confirmPayment,
      notes,
      amount
    } = this.props;

    updatePayment(mobile_payment, {notes, check_amount: amount})
      .then(() => {
        confirmPayment(
          mobile_payment,
          { ignore_check_amount_mismatch: true }
        );
      });
  }

  heading() {
    return (
      <div className="text-center">
        <h1>{this.props.entity_name}</h1>
        <h2>Payment Request</h2>
      </div>
    );
  }

  instructions() {
    return (
      <div className="form-group form-group-lg text-center lead">
          <div>
          View this payment request on your mobile device to capture
          a check payment.
        </div>
        {this.requestInstructions()}
      </div>
    );
  }

  requestInstructions() {
    if (this.props.payment_request.instructions) {
      return (
        <div className="well">
          {this.props.payment_request.instructions}
        </div>
      );
    }
  }

  amountInput() {
    const amount = accounting.formatMoney(this.props.amount, '');

    if (this.props.amountLocked) {
      return (
        <input
          className="form-control"
          name="amount"
          value={amount}
          disabled
        />
      );
    } else {
      return (
        <CurrencyInput
          className="form-control"
          name="amount"
          placeholder="Enter Amount"
          value={amount}
          onChange={this.handleAmountChange}
        />
      );
    }
  }

  amountField() {
    return (
      <div className="form-group form-group-lg">
        <label className="control-label" htmlFor="amount">
          Amount
        </label>
        <div className="input-group">
          <span className="input-group-addon">
            <Icon type="dollar-sign" size="large" />
          </span>
          {this.amountInput()}
        </div>
      </div>
    );
  }

  checkField() {
    return (
      <div className="form-group form-group-lg">
        <label className="control-label" htmlFor="amount">
          Check
        </label>
        <div>
          {this.checkFrontButton()}{' '}
          {this.checkRearButton()}
        </div>
      </div>
    );
  }

  checkButtonIcon(imageType) {
    let icon = this.props[imageType] ? 'check' : 'camera';
    if (this.props[`${imageType}Loading`]) icon = 'spinner fa-spin';
    return <i className={`fa fa-fw fa-${icon}`} />;
  }

  checkFrontButton() {
    return (
      <label className="btn btn-default btn-file btn-lg" htmlFor="checkFront">
        {this.checkButtonIcon('checkFront')}{' '}
        Check Front
        <input
          type="file"
          id="checkFront"
          name="checkFront"
          className="hidden"
          onChange={this.handleCheckImage}
          accept="image/*"
          capture="camera"
        />
      </label>
    );
  }

  checkRearButton() {
    return (
      <label className="btn btn-default btn-file btn-lg" htmlFor="checkRear">
        {this.checkButtonIcon('checkRear')}{' '}
        Check Back
        <input
          type="file"
          id="checkRear"
          name="checkRear"
          className="hidden"
          onChange={this.handleCheckImage}
          accept="image/*"
          capture="camera"
        />
      </label>
    );
  }

  notesField() {
    return (
      <div className="form-group form-group-lg">
        <label className="control-label" htmlFor="notes">
          Notes
        </label>
        <textarea
          name="notes"
          className="form-control"
          rows="3"
          value={this.props.notes || ''}
          onChange={this.handleNotesChange}
        />
      </div>
    );
  }

  submitButton() {
    if (this.props.confirmSubmitEnabled) return;

    return (
      <div className="text-center">
        <hr />
        <button
          type="submit"
          className="btn btn-primary btn-lg"
          onClick={this.handleSubmit}
        >
          Submit Payment
        </button>
      </div>
    );
  }

  confirmPaymentButton() {
    if (!this.props.confirmSubmitEnabled) return;

    return (
      <div className="text-center">
        <hr />
        <button
          type="submit"
          className="btn btn-warning btn-lg"
          onClick={this.handleConfirmSubmit}
        >
          Confirm Payment
        </button>
      </div>
    );
  }

  formErrors() {
    if (this.props.confirmSubmitEnabled) return;

    return (
      <FormErrors
        errors={this.props.errors}
        dismiss={this.props.clearErrors}
        heading={'Please correct the following errors:'}
      />
    );
  }

  amountDiffersWarning() {
    if (!this.props.confirmSubmitEnabled) return;

    return (
      <WarningMessage
        warningType={"danger"}
        alignment={"center"}
        message={"Check amount mismatch. To submit the payment regardless, click below."}
      />
    );
  }

  form() {
    if (!this.props.finished) {
      return (
        <form>
          {this.instructions()}
          {
            <SplitsTable
              splitsData={this.props.splitsData}
              setSplitState={this.props.setSplitState}
              totalAmount={this.props.payment_request.amount} />
          }
          {this.formErrors()}
          {this.amountField()}
          {this.checkField()}
          {this.notesField()}
          {this.amountDiffersWarning()}
          {this.submitButton()}
          {this.confirmPaymentButton()}
        </form>
      );
    }
  }

  done() {
    if (this.props.finished) {
      return (
        <div className="done text-center panel panel-success">
          <div className="panel-body">
            <h3>Thank You</h3>
            <h2 className="lead">Payment submitted successfully!</h2>
            <i className="fa fa-check-circle success-icon" />
          </div>
        </div>
      );
    }
  }

  unexpiredView() {
    return (
      <>
        {this.form()}
        {this.done()}
      </>
    );
  }

  expirationMessage() {
    return (
      <div className="alert alert-danger text-center">
        <h3>{this.props.expiration_message}</h3>
      </div>
    );
  }

  dateInformation() {
    const sentDate = this.props.payment_request.sent_on;
    const expirationDate = this.props.payment_request.expires_on;

    return (
      <div id="date-container">
        <div className="date-row">
          <b>Sent on:</b>
          <span>{sentDate}</span>
        </div>
        <div className="date-row">
          <b>Expired on:</b>
          <span>{expirationDate}</span>
        </div>
      </div>
    );
  }

  expiredView() {
    return (
      <>
        {this.expirationMessage()}
        {this.dateInformation()}
      </>
    );
  }

  render() {
    return (
      <div id="payment-request-screen" className="container">
        <div className="row">
          <div className="col-md-offset-3 col-md-6">
            {this.heading()}
            <br />
            {this.props.expired ? this.expiredView() : this.unexpiredView()}
          </div>
        </div>
      </div>
    );
  }
}

Screen.propTypes = {
  entity_name: PropTypes.string.isRequired,
  mobile_payment: PropTypes.object.isRequired,
  payment_request: PropTypes.object.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  amountLocked: PropTypes.bool.isRequired,
  notes: PropTypes.string,
  splitsData: PropTypes.object.isRequired,
  errors: PropTypes.array,
  expired: PropTypes.bool.isRequired,
  expiration_message: PropTypes.string.isRequired,
  finished: PropTypes.bool.isRequired,
  confirmSubmitEnabled: PropTypes.bool.isRequired,
  setSplitState: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired,
  updatePayment: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  confirmPayment: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};
