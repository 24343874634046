// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SplitsTable should render with data 1`] = `
<DocumentFragment>
  <div
    class="invoice_table panel panel-default"
  >
    <div
      class="panel-body table-responsive"
    >
      <table
        class="table table-striped table-hover"
        data-testid="table"
      >
        <thead>
          <tr>
            <th>
              Invoice #
            </th>
            <th>
              Due Date
            </th>
            <th
              class="text-right"
            >
              Amount Due
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="invoice_row"
          >
            <td />
            <td />
            <td
              class="text-right"
            >
              $0.00
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="panel-footer"
    >
      <div
        class="invoice_footer"
      >
        <div
          class="totals"
        >
          Total Amount Due: $0.00
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
