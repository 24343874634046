import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from 'reactstrap';
import { DatePicker } from 'shared/components/DatePicker';
import Modal from 'shared/components/Modal';
import format from 'shared/components/formatUtils';
import moment from 'moment';

export default class DateFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDateModalOpen: false,
      query: this.props.filterValue || '',
    };

    this.toggleDateModal = this.toggleDateModal.bind(this);
    this.startDateChanged = this.startDateChanged.bind(this);
    this.endDateChanged = this.endDateChanged.bind(this);
    this.clearDateFilter = this.clearDateFilter.bind(this);
    this.submitDateFilter = this.submitDateFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  handleFilterChange = (query) => {
    this.props.onChange(query);
  }

  label() {
    return (
      <label htmlFor={this.fieldName} className={"control-label"}>
        {this.props.filterLabel}
      </label>
    );
  }

  textField() {
    return (
      <div className="input-group">
        {this.dateFilter()}
        <input
          name={this.fieldName}
          className="form-control"
          value={this.props.filterValue || ''}
          placeholder={this.props.filterPlaceholder}
          onChange={this.handleFilterChange}
          autoComplete={"off"}
        />
        {this.clearButton()}
        {this.dateModal()}
      </div>
    );
  }

  get fieldName() {
    return `${this.props.filterKey}_filter`;
  }

  get emptyFilterValue() {
    return this.props.filterValue == undefined;
  }

  get filterFieldClass() {
    return this.emptyFilterValue ? '' : 'input-group';
  }

  dateFilter() {
    return (<div className={"input-group-addon"}>
      <button
        type={"button"}
        className={"date-picker-btn"}
        onClick={this.toggleDateModal}
        aria-label={"Toggle DatePicker"}>
        <i className={"far fa-calendar-alt"}></i>
      </button>
    </div>);
  }

  dateModal() {
    return (
      <Modal
        isOpen={this.state.isDateModalOpen}
        toggle={this.toggleDateModal}
        className="date-range-modal"
        title="Select Date Range"
        message={this.dateModalContent()}
        actions={this.dateModalActions()} />
    );
  }

  dateModalContent() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-6">
            <DatePicker
              inline
              fixedHeight
              selectsStart
              selected={this.state.startDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.startDateChanged} />
          </div>
          <div className="col-xs-6">
            <div className="pull-right">
              <DatePicker
                inline
                fixedHeight
                selectsEnd
                selected={this.state.endDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.endDateChanged} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  startDateChanged(date) {
    this.setState({ startDate: date });
  }

  endDateChanged(date) {
    this.setState({ endDate: date });
  }

  toggleDateModal() {
    this.setState({ isDateModalOpen: !this.state.isDateModalOpen });
  }

  dateModalActions() {
    return (
      <div>
        <Button color="default" onClick={this.clearDateFilter}>
          {"Clear"}
        </Button>
        <Button color="default" onClick={this.toggleDateModal}>
          {"Cancel"}
        </Button>
        <Button color="primary" onClick={this.submitDateFilter}>
          {"Submit"}
        </Button>
      </div>
    );
  }

  clearDateFilter() {
    this.setState({
      startDate: null,
      endDate: null,
      isDateModalOpen: false,
      query: ''
    });
    this.props.onChange('');
  }

  submitDateFilter() {
    const dates = [];

    if (this.state.startDate) {
      dates.push(format.date(this.state.startDate));
    } else {
      dates.push(format.date(moment()));
    }
    if (this.state.endDate) {
      dates.push(format.date(this.state.endDate));
    } else {
      dates.push(format.date(moment()));
    }

    const query = dates.join(' - ');
    this.setState({ isDateModalOpen: false, query });
    this.props.onChange(query);
  }

  clearButton() {
    if (this.emptyFilterValue) return;

    return (<div className={"input-group-addon"}>
      <button
        type={"button"}
        className={"clear-btn"}
        onClick={this.clearFilter}
        aria-label={`Clear ${this.props.filterLabel}`}>
        <i className={"far fa-times-circle"}></i>
      </button>
    </div>);
  }

  clearFilter() {
    this.setState({
      startDate: null,
      endDate: null,
      isDateModalOpen: false,
      query: ''
    }, () => {
      this.props.clearFilter(this.props.filterKey);
    });
  }

  render() {
    return (<div className={"form-group"}>
        {this.label()}
        {this.textField()}
    </div>
    );
  }
}

DateFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filterLabel: PropTypes.string.isRequired,
  filterValue: PropTypes.string,
  filterPlaceholder: PropTypes.string,
  showFilterLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired
};
