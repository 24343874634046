import React, { Component } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import moment from 'moment';
import Icon from 'shared/components/Icon';
import DeductionForm from '../components/DeductionForm';
import './styles';

export default class Screen extends Component {
  constructor(props) {
    super(props);
    this.editDeductionBtn = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.scrollToBottom(this.activityList);
    });
  }

  componentDidUpdate(prevProps) {
    this.handleRefresh(prevProps);
    this.handleEventsChange(prevProps);
  }

  handleStepChange = (event) => {
    const stepId = event.target.value;
    this.props.changeStep(this.props.deduction, stepId);
  }

  handleCommentChange = (event) => {
    const comment = event.target.value;
    this.props.setComment(comment);
  }

  handleCommentSubmit = () => {
    const { deduction, comment } = this.props;
    this.props.createComment(deduction, comment);
  }

  handleFetchPrevDeduction = () => {
    const { deduction, fetchPrevDeduction } = this.props;
    fetchPrevDeduction(deduction);
  }

  handleFetchNextDeduction = () => {
    const { deduction, fetchNextDeduction } = this.props;
    fetchNextDeduction(deduction);
  }

  handleRefresh = (prevProps) => {
    if (prevProps.lastEventAt != this.props.lastEventAt) {
      this.props.fetchEvents(this.props.deduction);
    }
  }

  handleEventsChange = (prevProps) => {
    if (prevProps.deduction.events != this.props.deduction.events) {
      this.scrollToBottom(this.activityList);
    }
  }

  handleAssigneeChange = (event) => {
    const assigneeId = event.target.value;
    this.props.updateAssignee(this.props.deduction, assigneeId);
  }

  scrollToBottom = (element) => {
    const scrollHeight = element.scrollHeight;
    const height = element.clientHeight;
    const maxScrollTop = scrollHeight - height;
    element.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  sectionHeading(heading) {
    return (
      <div className="pane_header">
        <span className="title">{heading}</span>
      </div>
    );
  }

  detailSection() {
    const ariaExpanded = this.props.editingDeduction ? 'true' : 'false';
    return (
      <div className="detail-column col-md-4">
        <section className="details-section" id="details-section-deduction" aria-label={"Deduction Details"}>
          <div className="pane_header">
            <span className="title">Details</span>
            <div className="actions-menu">
              <button
                href="#"
                ref={this.editDeductionBtn}
                className="btn btn-default btn-sm action-btn"
                onClick={this.props.toggleEditingDeduction}
                aria-expanded={ariaExpanded}
              >
                {"Edit"}
              </button>
            </div>
          </div>
          <div className="panel panel-default">
            {this.detailHeader()}
            <div className="panel-body">
              {this.deductionDetails()}
              {this.deductionForm()}
            </div>
          </div>
        </section>
        <section className="details-section" id="details-section-payment" aria-label={"Payment Information"}>
          {this.sectionHeading("Payment Items")}
          <div className="panel panel-default">
            {/* eslint-disable react/no-danger  */}
            <div
              dangerouslySetInnerHTML={{ __html: this.props.deduction.payment_items }}
              className="panel-body payment-items"
            />
          </div>
        </section>
      </div>
    );
  }

  deductionDetails() {
    if (this.props.editingDeduction) return;
    return (
      <div className="deduction-details">
        {this.detailInvoiceNumber()}
        {this.detailDeductionNumber()}
        {this.detailDeductionType()}
        {this.detailReferenceNumber()}
        {this.detailDescription()}
        {this.detailAddress()}
      </div>
    );
  }

  handleFormSubmit = (params) => {
    const { deduction, changeType, updateDeduction } = this.props;
    const { deduction_type_id } = params;

    if (deduction_type_id) {
      changeType(deduction, deduction_type_id)
        .then(() => {
          updateDeduction(deduction, params);
        });
    }
    else {
      updateDeduction(deduction, params);
    }
  }

  deductionForm() {
    if (!this.props.editingDeduction) return;
    return (
      <DeductionForm
        deduction={this.props.deduction}
        deduction_types={this.props.deduction_types}
        feature={this.props.feature}
        submitting={this.props.deductionPending}
        onSubmit={this.handleFormSubmit}
        onCancel={this.cancelEditingDeduction}
      />
    );
  }

  cancelEditingDeduction = () => {
    this.props.toggleEditingDeduction();
    this.editDeductionBtn.current.focus();
  }

  detailHeader() {
    if (this.props.editingDeduction) return;
    return (
      <div className="row">
        <div className="account-column col-lg-8">
          <div className="account-info">
            <div className="account-name">
              {this.props.deduction.payer.name}
            </div>
            <div className="account-number">
              {this.props.deduction.account.external_key}
            </div>
          </div>
        </div>
        <div className="amount-column col-lg-4">
          <div className="amount">
            {accounting.formatMoney(this.props.deduction.amount)}
          </div>
        </div>
      </div>
    );
  }

  detailInvoiceNumber() {
    if (!this.props.deduction.bill) return;
    return (
      <div className="detail">
        <div className="row">
          <div className="detail-label col-lg-6">
            Invoice #
          </div>
          <div className="detail-value col-lg-6">
            {this.props.deduction.bill.external_key}
          </div>
        </div>
      </div>
    );
  }

  detailDeductionNumber() {
    return (
      <div className="detail">
        <div className="row">
          <div className="detail-label col-lg-6">
            Deduction #
          </div>
          <div className="detail-value col-lg-6">
            {this.props.deduction.id}
          </div>
        </div>
      </div>
    );
  }

  detailDeductionType() {
    return (
      <div className="detail">
        <div className="row">
          <div className="detail-label col-lg-6">
            Deduction Type
          </div>
          <div className="detail-value col-lg-6 deduction-type">
            {this.props.deduction.type.code}
            {' - '}
            {this.props.deduction.type.description}
          </div>
        </div>
      </div>
    );
  }

  detailReferenceNumber() {
    if (this.props.deduction.type.reference_number_required) {
      return (
        <div className="detail">
          <div className="row">
            <div className="detail-label col-lg-6">
              {this.props.feature.deduction_reference_number_label}
            </div>
            <div className="detail-value col-lg-6 deduction-ref-num">
              {this.props.deduction.reference_number || ' - '}
            </div>
          </div>
        </div>
      );
    }
  }

  detailAddress() {
    if (this.props.deduction.type.address_required) {
      return (
        <div className="address">
          <h4 className="heading">Address</h4>
          {this.displayAddress(this.props.deduction)}
        </div>
      );
    }
  }

  detailDescription() {
    return (
      <div className="description">
        <h4 className="heading">Description</h4>
        {this.props.deduction.description || 'No description given.'}
      </div>
    );
  }

  activitySection() {
    return (
      <div className="col-md-6">
        <section id="activity-section" aria-label={"Activity Feed"}>
          {this.sectionHeading("Activity")}
          {this.activityFeed()}
          {this.commentSection()}
        </section>
      </div>
    );
  }

  activityFeed() {
    const { user } = this.props.deduction;
    const userName = user ? user.name : 'System';
    return (
      <div
        id="activity-feed"
        className="panel panel-default"
        ref={(element) => { this.activityList = element; }}
      >
        <div className="activity-group">
          <div className="timeline-date">
            {moment(this.props.deduction.created_at).format('L')}
          </div>
          <i className="timeline-icon fas fa-circle" />
          <div className="activity">
            <strong>{userName}</strong>
            {` created this deduction `}
            {moment(this.props.deduction.created_at).fromNow()}
            {'.'}
          </div>
        </div>
        {this.deductionEvents()}
      </div>
    );
  }

  deductionEvents() {
    const groups = this.groupEventsByDate(this.props.deduction.events);
    const groupDates = Object.keys(groups);
    return groupDates.map((date) => {
      const events = groups[date];
      return this.renderEvents(date, events);
    });
  }

  renderEvents(date, events) {
    return events.map((event, i) => {
      const timelineDate = (i == 0) ? this.timelineDisplayDate(date) : null;
      const timelineIcon = (i == 0) ? 'circle' : 'dot-circle';
      return (
        <div className="activity-group" key={event.id}>
          <div className="timeline-date">
            {timelineDate}
          </div>
          <i className={`timeline-icon fas fa-${timelineIcon}`} />
          {this.renderEvent(event)}
        </div>
      );
    });
  }

  timelineDisplayDate(eventDate) {
    const date = moment(eventDate);
    if (date.isSame(moment(), 'day')) return 'Today';
    else if (moment().diff(date, 'days') == 1) return 'Yesterday';
    else return date.format('L');
  }

  groupEventsByDate(events) {
    let eventHash = {};
    events.forEach(function(event) {
      if (eventHash[event.date]) {
        eventHash[event.date].push(event);
      } else {
        eventHash[event.date] = [event];
      }
    });
    return eventHash;
  }

  renderEvent(event) {
    switch (event.type) {
      case "Deductions::AssignmentEvent": {
        return this.assignmentEvent(event);
      }
      case "Deductions::CommentEvent": {
        return this.commentEvent(event);
      }
      case "Deductions::FieldUpdateEvent": {
        return this.fieldUpdateEvent(event);
      }
      case "Deductions::StepChangeEvent": {
        return this.stepChangeEvent(event);
      }
      case "Deductions::DeductionTypeChangeEvent": {
        return this.typeChangeEvent(event);
      }
      case "Deductions::AddressUpdateEvent": {
        return this.addressUpdateEvent(event);
      }
    }
  }

  assignmentEvent(event) {
    if (event.new_assignee) {
      return (
        <div className="activity">
          <strong>{event.user.name}</strong> assigned
          <strong>{` ${event.new_assignee.name} `}</strong>
          this deduction
          {` ${moment(event.created_at).fromNow()}.`}
        </div>
      );
    }
    else {
      return (
        <div className="activity">
          <strong>{event.user.name}</strong> unassigned this deduction
          {` ${moment(event.created_at).fromNow()}.`}
        </div>
      );
    }
  }

  commentEvent(event) {
    return (
      <div className="activity">
        <strong>{event.user.name}</strong> added a comment
        {` ${moment(event.created_at).fromNow()}:`}
        <div className="comment">
          {event.comment.content}
        </div>
      </div>
    );
  }

  stepChangeEvent(event) {
    return (
      <div className="activity">
        <strong>{event.user.name}</strong> changed the status of this deduction
        to <strong>{event.new_step.name}</strong>
        {` ${moment(event.created_at).fromNow()}.`}
      </div>
    );
  }

  typeChangeEvent(event) {
    return (
      <div className="activity">
        <strong>{event.user.name}</strong> changed the deduction type of this deduction
        to <strong>{event.new_deduction_type.description}</strong>
        {` ${moment(event.created_at).fromNow()}.`}
      </div>
    );
  }

  addressUpdateEvent(event) {
    const address = event.new_value;
    return (
      <div className="activity">
        <strong>{event.user.name}</strong> changed the address of this deduction
        {` ${moment(event.created_at).fromNow()}:`}
        <div className="field-update">
          {this.displayAddress(address)}
        </div>
      </div>
    );
  }

  displayAddress(address) {
    const {
      address_attn,
      address_1,
      address_2,
      city,
      address_state,
      zip_code,
      country
    } = address;

    const addressGiven =
      address_attn || address_1 || address_2 || city || address_state ||
        zip_code || country;

    if (addressGiven) {
      return (
        <div>
          <p>{address_attn}</p>
          <p>{address_1}</p>
          <p>{address_2}</p>
          <p>
            {
              [
                city,
                [address_state, zip_code, country].join(' ')
              ]
              .filter(value => value && value.trim().length)
              .join(', ')
            }
          </p>
        </div>
      );
    }
    else {
      return (
        <p>No address given.</p>
      );
    }
  }

  fieldUpdateEvent(event) {
    if (event.new_value) {
      return this.fieldUpdateDetails(event);
    }
    else {
      return (
        <div className="activity">
          <strong>{event.user.name}</strong> removed the {event.field_name}{' '}
          of this deduction
          {` ${moment(event.created_at).fromNow()}.`}
        </div>
      );
    }
  }

  fieldUpdateDetails(event) {
    return (
      <div className="activity">
        <strong>{event.user.name}</strong> updated the {event.field_name}{' '}
        of this deduction
        {` ${moment(event.created_at).fromNow()}:`}
        <div className="field-update">
          {event.new_value}
        </div>
      </div>
    );
  }

  commentSection() {
    const { comment, commentPending } = this.props;
    const submitEnabled = comment && comment.length && !commentPending;

    return (
      <div id="comment-section">
        <div className="pane_header"><label htmlFor="comment_field" className={"title"}>Comment</label></div>
        <textarea
          ref={(element) => { this.commendField = element; }}
          disabled={commentPending}
          className="comment-field form-control"
          name="comment"
          rows="2"
          placeholder="Write a comment"
          onChange={this.handleCommentChange}
          value={comment}
          id={"comment_field"}
        />
        <button
          disabled={!submitEnabled}
          className="btn btn-primary"
          type="button"
          onClick={this.handleCommentSubmit}
        >
          Add Comment
        </button>
      </div>
    );
  }

  actionSection() {
    return (
      <div className="col-md-2">
        <div id="action-section">
          {this.stepSection()}
          {this.assigneeSection()}
        </div>
      </div>
    );
  }

  navigation() {
    return (
      <nav
        className="navigation-container"
        role="navigation"
        aria-label="Pagination"
      >
        <div className="btn-group btn-group-xs">
          <button
            id="prev-deduction"
            aria-label="Previous"
            className="btn btn-default"
            type="button"
            disabled={!this.props.prevEnabled}
            onClick={this.handleFetchPrevDeduction}
          >
            <Icon type="caret-left" />
          </button>
          <button
            id="next-deduction"
            aria-label="Next"
            className="btn btn-default"
            type="button"
            disabled={!this.props.nextEnabled}
            onClick={this.handleFetchNextDeduction}
          >
            <Icon type="caret-right" />
          </button>
        </div>
      </nav>
    );
  }

  stepSection() {
    return (
      <div id="status-section">
        <h1 className="section-heading">Status</h1>
        {this.stepSelect()}
      </div>
    );
  }

  stepSelect() {
    return (
      <select
        className="form-control"
        name="step"
        value={this.props.deduction.deduction_step.id}
        onChange={this.handleStepChange}
        disabled={this.props.stepPending}
      >
        {this.props.steps.map((step) => this.stepLabelValue(step))}
      </select>
    );
  }

  stepLabelValue(step) {
    if (!step.publishable) {
      return (
        <option key={step.id} value={step.id}>
          {step.name}
        </option>
      );
    } else {
      if (this.props.deduction.type.reference_number_required) {
        const enabled = this.props.deduction.reference_number;
        if (enabled) {
          return (
            <option key={step.id} value={step.id}>
              {step.name}
            </option>
          );
        } else {
          return (
            <option key={step.id} value={step.id} disabled>
              {step.name + " (" + this.props.feature.deduction_reference_number_label + " required)"}
            </option>
          );
        }
      } else if (this.props.deduction.type.address_required) {
        const enabled = this.props.deduction.address_1 && this.props.deduction.city && this.props.deduction.address_state && this.props.deduction.zip_code && this.props.deduction.country;
        if (enabled) {
          return (
            <option key={step.id} value={step.id}>
              {step.name}
            </option>
          );
        } else {
          return (
            <option key={step.id} value={step.id} disabled>
              {step.name + " (Address required)"}
            </option>
          );
        }
      } else {
        return (
          <option key={step.id} value={step.id}>
            {step.name}
          </option>
        );
      }
    }
  }

  assigneeSection() {
    return (
      <div id="assignee-section">
        <h1 className="section-heading">
          Assignee
          {' '}
          <i className="far fa-user fa-fw" />
        </h1>
        {this.assigneeSelect()}
      </div>
    );
  }

  assigneeSelect() {
    return (
      <select
        className="form-control"
        name="assignee"
        onChange={this.handleAssigneeChange}
        value={this.props.deduction.assignee_id || ''}
        disabled={this.props.assigneePending}
      >
        <option>Unassigned</option>
        {this.assigneeOptions()}
      </select>
    );
  }

  assigneeOptions() {
    return this.props.operators.map((operator) => {
      return (
        <option
          key={operator.id}
          value={operator.user.id}
        >
          {operator.user.name}
        </option>
      );
    });
  }

  render() {
    return (
      <div id="deduction-screen" className="row">
        {this.navigation()}
        {this.detailSection()}
        {this.activitySection()}
        {this.actionSection()}
      </div>
    );
  }
}

Screen.propTypes = {
  assigneePending: PropTypes.bool,
  changeStep: PropTypes.func.isRequired,
  changeType: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  comment: PropTypes.string,
  commentPending: PropTypes.bool,
  createComment: PropTypes.func.isRequired,
  deduction_types: PropTypes.array.isRequired,
  deduction: PropTypes.object.isRequired,
  deductionPending: PropTypes.bool,
  editingDeduction: PropTypes.bool,
  feature: PropTypes.object.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  fetchNextDeduction: PropTypes.func.isRequired,
  fetchPrevDeduction: PropTypes.func.isRequired,
  lastEventAt: PropTypes.object,
  nextEnabled: PropTypes.bool,
  operators: PropTypes.array.isRequired,
  prevEnabled: PropTypes.bool,
  setComment: PropTypes.func.isRequired,
  stepPending: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  toggleEditingDeduction: PropTypes.func.isRequired,
  updateAssignee: PropTypes.func.isRequired,
  updateDeduction: PropTypes.func.isRequired,
};
