import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import accounting from 'accounting';
import Pagination from 'shared/components/Pagination';

class SplitsTable extends React.Component {

  constructor(props) {
    super(props);

    this.handlePageChange({'selected': 1});
  }

  handlePageChange = (data) => {
    const { splitsData } = this.props;

    const currentPage = data['selected'];
    const offset = (currentPage - 1) * splitsData.pageLimit;
    const currentSplits = splitsData.splits.slice(offset, offset + splitsData.pageLimit);
    const totalPages = Math.ceil(splitsData.splits.length / 5);

    this.props.setSplitState(currentSplits, currentPage, totalPages);
  }

  splits() {
    if (!this.props.splitsData.currentSplits) return null;

    return (
      <table data-testid="table" className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Invoice #</th>
            <th>Due Date</th>
            <th className="text-right">Amount Due</th>
          </tr>
        </thead>
        <tbody>
          {this.props.splitsData.currentSplits.map(
              split =>
                <tr key={split.id} className="invoice_row">
                  <td>{split.invoice_num}</td>
                  <td>{split.due_date}</td>
                  <td className="text-right">
                    {accounting.formatMoney(split.amount_due)}
                  </td>
                </tr>
          )}
        </tbody>
      </table>
    );
  }

  totalAmount() {
    const totalAmount = accounting.formatMoney(this.props.totalAmount);

    return (
      <div className="totals">
        Total Amount Due: {totalAmount}
      </div>
    );
  }

  pagination() {
    const { splitsData } = this.props;
    const totalSplits = splitsData.splits.length;

    if (totalSplits === 0) return null;

    return (
      <Pagination
        totalItems={totalSplits}
        itemsPerPage={splitsData.pageLimit}
        onPageChange={this.handlePageChange}
        currentPage={splitsData.currentPage} />
    );
  }

  render() {
    if (!this.props.splitsData.currentSplits.length) return null;

    return (
      <div className="invoice_table panel panel-default">
        <div className="panel-body table-responsive">
          {this.splits()}
        </div>
        <div className="panel-footer">
          <div className="invoice_footer">
            {this.pagination()}
            {this.totalAmount()}
          </div>
        </div>
      </div>
    );
  }
}

SplitsTable.propTypes = {
  splitsData: PropTypes.object.isRequired,
  setSplitState: PropTypes.func.isRequired,
  totalAmount: PropTypes.string.isRequired
};

export default SplitsTable;
