import "@babel/polyfill";
import "@eastdesire/jscolor";
import "jquery";
import "jquery-ui";
import "jquery-ujs";
import "controllers";
import "expose-loader?exposes=AutoNumeric!autonumeric";

import 'styles/application';

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
