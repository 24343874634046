import 'bootstrap';
import React from 'react';
import { PropTypes } from 'prop-types';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import reducer from './screen/reducer';
import { default as Screen } from './screen';
import { setApiBaseUrl } from 'shared/api';
import { setInitialData } from './screen/actions';

const store = configureStore(reducer);

class App extends React.Component {
  componentWillMount() {
    const {
      api_base_url,
      initial_data
    } = this.props;

    setApiBaseUrl(api_base_url);
    store.dispatch(setInitialData(initial_data));
  }

  render() {
    return (
      <Provider store={store}>
        <Screen />
      </Provider>
    );
  }
}

App.propTypes = {
  api_base_url: PropTypes.string.isRequired,
  initial_data: PropTypes.object.isRequired
};

export default App;
