import React from 'react';
import { default as ReactCurrencyInput } from 'react-currency-input';

class CurrencyInput extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, maskedValue, floatValue) {
    this.props.onChange(floatValue);
  }

  render() {
    return (
      <ReactCurrencyInput
        placeholder="0.00"
        autoFocus={this.props.autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
        allowNegative={this.props.allowNegative}
        className={this.props.className}
        id={this.props.id}
        name={this.props.name}
        value={this.props.value}
        onClick={this.props.onClick}
        onChangeEvent={this.handleChange}
        onBlur={this.props.onBlur}
      />
    );
  }
}

CurrencyInput.propTypes = ReactCurrencyInput.propTypes;

export default CurrencyInput;
