import React from 'react';
import { PropTypes } from 'prop-types';
import ReactPaginate from 'react-paginate';
import Icon from 'shared/components/Icon';

class Pagination extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      totalItems: this.props.totalItems,
      currentPage: this.getCurrentPage(this.props.currentPage)
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      totalItems: nextProps.totalItems,
      currentPage: this.getCurrentPage(nextProps.currentPage)
    });
  }

  getCurrentPage(currentPage) {
    return currentPage ? parseInt(currentPage) - 1 : 0;
  }

  handlePageChange(data) {
    this.props.onPageChange({selected: data.selected + 1});
  }

  setCurrentPage(currentPage) {
    this.setState({currentPage});
  }

  setTotalItems(totalItems) {
    this.setState({totalItems});
  }

  itemCount(currentPage, pageCount, shownItemsCount) {
    const actualCurrentPage = Math.ceil(currentPage + 1);
    if (actualCurrentPage == pageCount) {
      return (
        <div className="item-count">
          {shownItemsCount} - {this.state.totalItems} of {this.state.totalItems}
        </div>
      );
    } else {
      return (
        <div className="item-count">
          {shownItemsCount} - {Math.ceil(this.props.itemsPerPage * actualCurrentPage)} of {this.state.totalItems}
        </div>
      );
    }
  }

  render() {
    const pageCount = Math.ceil(this.state.totalItems / this.props.itemsPerPage);
    const currentPage = this.state.currentPage;
    const shownItemsCount = Math.ceil((this.props.itemsPerPage - (this.props.itemsPerPage - 1)) + (currentPage * this.props.itemsPerPage));
    if (pageCount > 1) {
      return (
        <span className="pagination-container">
          <ReactPaginate
            previousLabel={<Icon type="angle-left" fixedWidth={false} />}
            nextLabel={<Icon type="angle-right" fixedWidth={false} />}
            breakLabel={<span>...</span>}
            forcePage={currentPage}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"} />
            {this.itemCount(currentPage, pageCount, shownItemsCount)}
        </span>
      );
    }
    else {
      return null;
    }
  }
}

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Pagination;
