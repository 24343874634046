import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

export default class TextFilter extends Component {
  handleFilterChange = (event) => {
    this.props.onChange(event.target.value);
  }

  label() {
    return (
      <label htmlFor={this.fieldName} className={"control-label"}>
        {this.props.filterLabel}
      </label>
    );
  }

  textField() {
    return (
        <input
          name={this.fieldName}
          type="search"
          className="form-control"
          value={this.props.filterValue || ''}
          placeholder={this.props.filterPlaceholder}
          onChange={this.handleFilterChange}
          autoComplete={"off"}
        />);
  }

  get fieldName() {
    return `${this.props.filterKey}_filter`;
  }

  get emptyFilterValue() {
    return this.props.filterValue == undefined;
  }

  get filterFieldClass() {
    return this.emptyFilterValue ? '' : 'input-group';
  }

  clearButton() {
    if (this.emptyFilterValue) return;

    return (<div className={"input-group-addon"}>
      <button
        type={"button"}
        className={"clear-btn"}
        onClick={() => this.props.clearFilter(this.props.filterKey)}
        aria-label={`Clear ${this.props.filterLabel}`}>
        <i className={"far fa-times-circle"}></i>
      </button>
    </div>);
  }

  render() {
    return (<div className={"form-group"}>
        {this.label()}
        {this.textField()}
    </div>
    );
  }
}

TextFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filterLabel: PropTypes.string.isRequired,
  filterValue: PropTypes.string,
  filterPlaceholder: PropTypes.string,
  showFilterLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired
};