import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ListFilter from 'shared/components/ListFilter';
import TextFilter from 'shared/components/TextFilter';
import DateFilter from 'shared/components/DateFilter';
import qs from 'qs';
import './styles';
import './react_datepicker';

export default class Screen extends Component {

  componentDidUpdate(prevProps) {
    this.handleFilterChanged(prevProps);
  }

  handleFilterChanged = prevProps => {
    const { search_table, lastChangedAt } = this.props;

    if (search_table && prevProps.lastChangedAt != lastChangedAt) {
      window['submit_search']('', $(search_table));
    }
  }

  applyFilters = () => {
    const params = this.filterParams();
    this.submit(params);
  }

  resetFilters = () => {
    const params = this.resetParams();
    this.submit(params);
  }

  submit = (params) => {
    const { submit_url } = this.props;
    const queryString = qs.stringify(params, { arrayFormat: 'brackets' });

    if (submit_url) {
      window.location = submit_url + '?' + queryString;
    }
  }

  filterParams = () => {
    const params = { ...this.props.params };

    this.props.filters.forEach((filter) => {
      if (filter.type == 'text' || filter.type == 'datepicker') {
        params[filter.key] = filter.value;
      }
      else {
        const values = filter.items
          .filter(item => item.checked)
          .map(item => item.id);

        params[filter.key] = values.length ? values : '';
      }
    });

    return params;
  }

  resetParams() {
    const params = { ...this.props.params };

    this.props.filters.forEach((filter) => {
      params[filter.key] = '';
    });

    return params;
  }

  actions() {
    if (!this.props.show_actions) return;

    return (
      <div className="actions pull-right">
        {this.resetButton()}{' '}
        {this.applyButton()}
      </div>
    );
  }

  resetButton() {
    return (
      <button
        type="button"
        className="btn btn-default btn-sm"
        onClick={this.resetFilters}
      >
        <i className="fas fa-fw fa-undo" /> Reset
      </button>
    );
  }

  applyButton() {
    return (
      <button
        type="button"
        className="btn btn-success btn-sm"
        onClick={this.applyFilters}
      >
        <i className="fas fa-fw fa-check" /> Apply
      </button>
    );
  }

  filters() {
    const filters = this.props.filters.filter(filter => !filter.custom);

    return filters.map((filter) => {
      switch (filter.type) {
        case 'text': {
          return (
            <div key={filter.key} className={"col-sm-3"}>
              <TextFilter
                key={filter.key}
                filterKey={filter.key}
                filterLabel={filter.label}
                filterPlaceholder={filter.placeholder}
                filterValue={filter.value}
                onChange={(value) => { this.props.setFilter(filter.key, value); }}
                clearFilter={this.props.clearFilter}
              />
            </div>);
        }
        case 'datepicker': {
          return (
            <div key={filter.key} className={"col-sm-3"}>
            <DateFilter
              key={filter.key}
              filterKey={filter.key}
              filterLabel={filter.label}
              filterPlaceholder={filter.placeholder}
              filterValue={filter.value}
              onChange={(value) => { this.props.setFilter(filter.key, value); }}
              clearFilter={this.props.clearFilter}
            />
            </div>);
        }
        default: {
          return (
            <div key={filter.key} className={"col-sm-3"}>
              <ListFilter
                type={filter.type}
                key={filter.key}
                filterKey={filter.key}
                filterLabel={filter.label}
                filterItems={filter.items}
                visible={filter.visible}
                hideLabelOnSelect={filter.hide_label_on_select}
                toggleFilterItem={this.props.toggleFilterItem}
                clearFilter={this.props.clearFilter}
                id={`${filter.key}_select`}
              />
            </div>);
        }
      }
    });
  }

  customFilters() {
    const customFilters = this.props.filters.filter(filter => filter.custom);
    const numColumns = 4;
    const rows = this.splitArray(customFilters, numColumns);

    if (!rows.length) return;

    return (
      <div>
        <hr className="filters-separator" />
        {this.customFilterRows(rows)}
      </div>
    );
  }

  customFilterRows(rows) {
    return rows.map((columns, i) => {
      return (
        <div className="row" key={i}>
          {this.customFilterColumns(columns)}
        </div>
      );
    });
  }

  customFilterColumns(columns) {
    return columns.map((filter, i) => {

      const handleChange = (event) => {
        this.props.setFilter(filter.key, event.target.value);
      };

      return (
        <div className="col-sm-3" key={i}>
          <div className="form-group form-group-sm">
            <label htmlFor={filter.id}>
              {filter.label}
            </label>
            <input
              name={`${filter.key}_filter`}
              value={filter.value || ''}
              type="search"
              className="form-control"
              id={filter.id}
              placeholder={filter.label}
              onChange={handleChange}
            />
          </div>
        </div>
      );
    });
  }

  splitArray = (array, size) => {
    const results = [];

    while (array.length) {
      results.push(array.splice(0, size));
    }

    return results;
  }

  render() {
    return (
      <div className={`filters-component ${this.props.css_class} row`}>
        {this.filters()}
        {this.customFilters()}
        {this.actions()}
      </div>
    );
  }
}

Screen.defaultProps = {
  show_actions: true
};

Screen.propTypes = {
  submit_url: PropTypes.string,
  search_table: PropTypes.string,
  show_actions: PropTypes.bool,
  params: PropTypes.object,
  css_class: PropTypes.string,
  filters: PropTypes.array.isRequired,
  toggleFilterItem: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  lastChangedAt: PropTypes.object
};
