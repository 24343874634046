import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class FormErrors extends Component {

  constructor(props) {
    super(props);

    this.state = {
      displayAlertArea: false,
      showAlert: false
    };
  }

  componentDidMount() {
    const state = this.context.store.getState();
    this.alertHeading
      = this.props.heading || state.i18n.portal.form_errors.error_message;
    if (this.props.errors) {
      this.showAlert();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.errors && this.props.errors) {
      $("body, .modal").scrollTop(0);
      this.showAlert();
    }
    if (prevProps.errors && !this.props.errors) {
      this.handleDismiss();
    }
  }

  showAlert = () => {
    this.setState({
      showAlert: true,
    }, () => {
      window.setTimeout(() => {
        this.setState({
          fade: "fade in"
        });
      }, 300);
    });
  }

  handleDismiss = () => {
    this.setState({
      showAlert: false,
    }, () => {
      window.setTimeout(() => {
        this.setState({
          fade: "fade out"
        });
      }, 300);
    });
  }

  heading() {
    if (!this.props.hideHeader) {
      return (
        <h4 className="alert-heading">{this.alertHeading}</h4>
      );
    }
  }

  errorMessages() {
    if (typeof this.props.errors == 'string') {
      return this.props.errors;
    } else {
      return (
        <div>
          {this.heading()}
          <ul className="errors">
            {this.props.errors.map(this.renderError)}
          </ul>
        </div>
      );
    }
  }

  renderError(error, i) {
    return <li key={i}>{error}</li>;
  }

  displayErrors() {
    const { showAlert, fade } = this.state;
    if (!this.props.errors) return;
    if (showAlert) {
      return (
        <div className="form-errors">
          <div className={`alert alert-danger alert-dismissable ${fade}`}>
            <button className="close" type="button" onClick={this.handleDismiss}>
              &times;
            </button>
            {this.errorMessages()}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.displayErrors()}
      </div>
    );
  }
}

FormErrors.contextTypes = {
  store: PropTypes.object.isRequired
};

FormErrors.propTypes = {
  refProp: PropTypes.object,
  errors: PropTypes.array,
  hideHeader: PropTypes.bool,
  dismiss: PropTypes.func,
  heading: PropTypes.string
};

export default FormErrors;

