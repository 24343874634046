import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Select from "react-select";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 5
  })
};

export default class ListFilter extends Component {
  filterItems() {
    let items = [];

    const headers = this.props.filterItems.map( item => item.header)
      .filter((value, index, self) => self.indexOf(value) === index);

    headers.map(header => {
      const opts = this.props.filterItems.filter(item => item.header === header);

      items.push({label: header, options: opts});
    });

    return items;
  }

  get selectedItems() {
    return this.props.filterItems.filter( item => item.checked);
  }

  handleItemChange = (options, actionMeta) => {
    let items = this.selectedItems;

    if (this.singleValue) {
      items.map(item => this.props.toggleFilterItem(this.props.filterKey, item.id, false));

      if (options != null) {
        this.props.toggleFilterItem(this.props.filterKey, options.id, true);
      }
    } else {
      options.map(option => {
        this.props.toggleFilterItem(this.props.filterKey, option.id, true);
      });

      items.map(item => {
        const option = options.find(opt => opt.id === item.id);
        if (option === undefined) {
          this.props.toggleFilterItem(this.props.filterKey, item.id, false);
        }
      });
    }

  }

  get singleValue() {
    return this.props.type === 'radio';
  }

  selectBox() {
    if (this.singleValue) {
      return <Select isClearable
                     id={this.props.id}
                     styles={customStyles}
                     onChange={this.handleItemChange}
                     options={this.filterItems()}
                     name={`${this.props.filterKey}`}
                     defaultValue={this.selectedItems} />;
    } else {
      return <Select isMulti
                     id={this.props.id}
                     styles={customStyles}
                     onChange={this.handleItemChange}
                     options={this.filterItems()}
                     name={`${this.props.filterKey}[]`}
                     defaultValue={this.selectedItems} />;
    }
  }

  render() {
    if (!this.props.visible || !this.props.filterItems.length) return null;

    return <div>
      <label htmlFor={this.props.id}>{this.props.filterLabel}</label>
      {this.selectBox()}
    </div>;
  }
}

ListFilter.defaultProps = {
  visible: true,
  type: 'checkbox'
};

ListFilter.propTypes = {
  type: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  filterLabel: PropTypes.string.isRequired,
  filterItems: PropTypes.array.isRequired,
  toggleFilterItem: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  hideLabelOnSelect: PropTypes.bool,
  visible: PropTypes.bool,
  id: PropTypes.string.isRequired
};