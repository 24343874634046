import path from 'path';
import { default as http } from 'axios';

const apiEndpoint = (url) => {
  return path.join(http.defaults.baseURL, url);
};

const setApiBaseUrl = (url) => {
  http.defaults.baseURL = url;
};

const setDefaultHeader = (name, value) => {
  http.defaults.headers.common[name] = value;
};

const csrfToken = $('meta[name="csrf-token"]').attr('content');

http.defaults.headers.common['X-CSRF-Token'] = csrfToken;
http.defaults.headers.common['Accept'] = 'application/json';
http.defaults.headers.common['Content-Type'] = 'application/json';

export {
  http,
  apiEndpoint,
  setApiBaseUrl,
  setDefaultHeader
};
